import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import { formatPace } from '../utils/utils';

import { withStyles, AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
};

class MenuAppBar extends React.Component {

  handleEditClick = () => {
    if (this.props.goalExpanded) {
      this.props.handleUpdatePace('goalPace');
    }
    this.props.toggleEditGoal();
    window.scrollTo(0, 0);
  };

  handleInfoClick = () => {
    this.props.toggleInfo();
    window.scrollTo(0, 0);
  }

  render() {
    const { classes } = this.props;
    const pace_verbose = (this.props.paceUnits === 'mi') ? formatPace(this.props.goalPace.spmi, " per mile") : formatPace(this.props.goalPace.spkm, " per kilometer")

    return (
      <AppBar position="sticky">
        <Toolbar>
          <div style={{display:"flex", flexDirection: "column", marginTop:'6px'}} className={classes.grow}>
            <Typography variant="caption" color="inherit" style={{lineHeight:1}} noWrap>
               Goal Race Pace
            </Typography>
            <Typography variant="h6" color="inherit" onClick={this.props.togglePaceUnits}>
              {pace_verbose}
            </Typography>
          </div>
          <div>
            <IconButton color="inherit" title="Edit race goal" onClick={this.handleEditClick}>
              <EditIcon />
            </IconButton>
            <IconButton color="inherit" title="What pace!?" onClick={this.handleInfoClick}>
              <InfoIcon />
            </IconButton>
          </div>

        </Toolbar>
      </AppBar>
    );
  }
}

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuAppBar);
