import moment from 'moment';
// eslint-disable-next-line
import momentDurationFormatSetup from 'moment-duration-format'
import convert from 'convert-units';
import numeral from 'numeral';

export function getDistances(distance, units){
  // expects units as either "mi" or "km"
  let mi = distance;
  let km = convert(distance).from(units).to('km');
  if (units === 'km') {
    mi = convert(distance).from(units).to('mi');
    km = distance;
  }

  return({mi: mi, km: km});
}

export function getPace(hour, minute, second, distance, units){
  // expects units as either "mi" or "km"
  let d = getDistances(distance, units);

  const seconds = hour * 3600 + minute * 60 + second
  const spkm = seconds / d.km;
  const spmi = seconds / d.mi;

  return ({spmi: spmi, spkm:spkm });
}

export function getDuration(hours, minutes, seconds, format="h:mm:ss"){
  const secs = hours * 3600 + minutes * 60 + seconds
  return {seconds: secs, formatted: moment.duration(secs, 'seconds').format(format)};
}

export function formatDuration(seconds, template="h:*mm:ss"){
  return moment.duration(seconds, 'seconds').format(template);
}

export function formatDistance(distance, units, label=false, template='0,0.0'){
  var dist = numeral(distance).format(template);
  return (label) ? (dist + label) : dist;
}

export function formatPace(seconds, label=false, template="*m:ss"){
  // seconds: pace in seconds per unit
  // label: optional label to be appended to pace
  // template: optional pace formatting
  var pace = moment.duration(seconds, 'seconds').format(template);
  return (label) ? (pace + label) : pace;
}
