import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ls from 'local-storage';
import { createMuiTheme } from '@material-ui/core/styles';
import Efforts from './efforts/Efforts';
import Splits from './splits/Splits';

import Info from './info/Info';
import Intervals from './intervals/Intervals';
import MenuAppBar from './header/MenuAppBar';
import EditGoal from './goal/EditGoal';
import Footer from './footer/Footer';
import { formatPace, getPace } from './utils/utils';
import { indigo } from '@material-ui/core/colors';

import { CssBaseline, MuiThemeProvider } from '@material-ui/core';

// initialize Google Analytics
const trackingId = 'UA-167308452-1';
ReactGA.initialize(trackingId,
                  { titleCase: false,
                    debug: false
                  });

// kipchoge pace by default
let goalHours = 1;
let goalMinutes = 59;
let goalSeconds = 40;
let goalRace = 'm';
let goalDistance = 26.2;
let goalDistanceUnits = "mi";
let paceUnits = "mi";
let goalPace = getPace(goalHours, goalMinutes, goalSeconds, goalDistance, goalDistanceUnits, paceUnits);

// fonts & theme
require('typeface-roboto');

const theme = createMuiTheme({
  palette: {
    primary: indigo,
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
  },
  overrides: {
    MuiBottomNavigationAction: {
      label: {
        fontSize: 14,
        '&$selected': {
          fontSize: 14,
        },
      },
    },
    MuiTableSortLabel: {
      icon: {
        fontSize: "inherit",
      },
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.handleUpdateField = this.handleUpdateField.bind(this);
    this.handleUpdatePace = this.handleUpdatePace.bind(this);
    this.handleUpdatePaceTraining = this.handleUpdatePaceTraining.bind(this);
    this.togglePaceUnits = this.togglePaceUnits.bind(this);
    this.toggleEditGoal = this.toggleEditGoal.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.handlePageNav = this.handlePageNav.bind(this);

    this.state = {};
    // settings
    this.state.paceUnits = ls('paceUnits') || paceUnits;
    this.state.page = ls('page') || 'splits';
    this.state.isGoalOpen = false; // collapsed by default
    this.state.isInfoOpen = false;
    // restore from local storage, otherwise default
    // have to check for null values, since they can be 0 and evaluate false
    this.state.goalRace = (ls('goalRace') === null) ? goalRace : ls('goalRace');
    this.state.goalHours = (ls('goalHours') === null) ? goalHours : ls('goalHours');
    this.state.goalMinutes = (ls('goalMinutes') === null) ? goalMinutes : ls('goalMinutes');
    this.state.goalSeconds = (ls('goalSeconds') === null) ? goalSeconds : ls('goalSeconds');
    this.state.goalDistance = (ls('goalDistance') === null) ? goalDistance : ls('goalDistance');
    this.state.goalDistanceUnits = ls('goalDistanceUnits') || goalDistanceUnits;
    // paces
    this.state.goalPace = ls('goalPace') || goalPace;
    this.state.trainingPace = ls('trainingPace') || goalPace;

    // analytics
    ReactGA.pageview(this.state.page);
  }

  handlePageNav(value) {
    this.setState({ page: value });
    // save to local storage
    ls('page', value);
    ReactGA.pageview(value);
  }

  togglePaceUnits() {
    let p_units = "mi";
    if (this.state.paceUnits === "mi") {
      p_units = "km"
    }
    this.setState({ paceUnits: p_units });
    // save to local storage
    ls('paceUnits', p_units);
    ReactGA.event({
      category: 'Settings',
      action: 'Toggled Pace Units',
      label: p_units
    });
  }

  toggleEditGoal() {
    if (!this.state.isGoalOpen) {
      ReactGA.modalview('/race-goal');
    }
    this.setState(state => ({
      isGoalOpen: !state.isGoalOpen
    }));
  }

  toggleInfo() {
    if (!this.state.isInfoOpen) {
      ReactGA.modalview('/info');
    }
    this.setState(state => ({
      isInfoOpen: !state.isInfoOpen
    }));
  }

  handleUpdateField(name, value) {
    this.setState({
      [name]: value,
    });
    // save to local storage
    ls(name, value);
  };

  handleUpdatePace(name) {
    let pace = getPace(this.state.goalHours,
                       this.state.goalMinutes,
                       this.state.goalSeconds,
                       this.state.goalDistance,
                       this.state.goalDistanceUnits
                       );
    this.setState({ [name]: pace });
    // save to local storage
    ls(name, pace);
    ReactGA.event({
      category: 'Settings',
      action: 'Update Goal Race Pace',
      label: formatPace(pace.spmi) + ' [' + this.state.goalDistance + ' ' + this.state.goalDistanceUnits + ']'
    });
  }

  handleUpdatePaceTraining(seconds, units) {
    let pace = getPace(0, 0, seconds, 1, units, units);
    this.setState({
      trainingPace: pace
    })
    // save to local storage
    ls('trainingPace', pace)
  }

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <MenuAppBar
                goalPace={this.state.goalPace}
                paceUnits={this.state.paceUnits}
                goalExpanded={this.state.isGoalOpen}
                infoExpanded={this.state.isInfoOpen}
                activePage={this.state.page}
                updatePace={this.updatePace}
                togglePaceUnits={this.togglePaceUnits}
                toggleEditGoal={this.toggleEditGoal}
                toggleInfo={this.toggleInfo}
                handlePageNav={this.handlePageNav}
                handleUpdatePace={this.handleUpdatePace}
          />
          <EditGoal
                goalRace={this.state.goalRace}
                goalHours={this.state.goalHours}
                goalMinutes={this.state.goalMinutes}
                goalSeconds={this.state.goalSeconds}
                goalDistance={this.state.goalDistance}
                goalDistanceUnits={this.state.goalDistanceUnits}
                paceUnits={this.state.paceUnits}
                expanded={this.state.isGoalOpen}
                toggleEditGoal={this.toggleEditGoal}
                handleUpdateField={this.handleUpdateField}
                handleUpdatePace={this.handleUpdatePace}
          />
          <Info
                expanded={this.state.isInfoOpen}
                toggleInfo={this.toggleInfo}
          />
          { this.state.page === "splits" &&
          <Splits
                pace={this.state.goalPace}
                goalDistance={this.state.goalDistance}
                goalDistanceUnits={this.state.goalDistanceUnits}
                paceUnits={this.state.paceUnits}
          /> }
          { this.state.page === "intervals" &&
          <Intervals
                pace={this.state.goalPace}
          /> }
          { this.state.page === "effort" &&
          <Efforts
                pace={this.state.goalPace}
                paceUnits={this.state.paceUnits}
          /> }
          <Footer
                handlePageNav={this.handlePageNav}
                activePage={this.state.page}
          />
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

export default App;
