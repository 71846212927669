import React from 'react';
import PropTypes from 'prop-types';
import TimerIcon from '@material-ui/icons/Timer';
import UpdateIcon from '@material-ui/icons/Update';
import BatteryCharging90Icon from '@material-ui/icons/BatteryCharging90';

import { withStyles, AppBar, BottomNavigation, BottomNavigationAction } from '@material-ui/core';

const styles = {
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  root: {
  },
};

class Footer extends React.Component {

  handleChange = (event, value) => {
    this.props.handlePageNav(value);
  };

  render() {
    const { classes } = this.props;

    return (
      <AppBar position="fixed" color="secondary" className={classes.appBar}>
        <BottomNavigation value={this.props.activePage} onChange={this.handleChange} className={classes.root}>
          <BottomNavigationAction label="Splits" value="splits" icon={<UpdateIcon />} />
          <BottomNavigationAction label="Intervals" value="intervals" icon={<TimerIcon />} />
          <BottomNavigationAction label="Effort" value="effort" icon={<BatteryCharging90Icon />} />
        </BottomNavigation>
      </AppBar>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
