import React from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';

import { formatPace } from '../utils/utils';

import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip,
} from '@material-ui/core';

// array of goal race pace effort levels in percentage
let percentages = [60, 65, 70, 75, 80, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94,
                   95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 115,
                   120]

function generateEffort(pace) {
  // returns array of efforts from provided pace percentages
  // pace: provided in seconds per distince unit (mi/km)
  let efforts = []
  for (let i = 0; i < percentages.length; i++) {
    efforts.push({id: i, effort: percentages[i], pace: pace * (2 - percentages[i] / 100)});
  }
  return efforts;
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'pace', align: 'left', disablePadding: false, label: 'Pace' },
  { id: 'effort', align: 'right', disablePadding: false, label: 'Effort' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  }
  render() {
    const { order, orderBy } = this.props;
    return (
      <TableHead>
        <TableRow>
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                align={row.align}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title={'Sort by ' + row.label }
                  placement={row.align === 'right' ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};

const styles = theme => ({
  root: {
    // width: '100%',
    // marginTop: theme.spacing.unit * 3,
  },
  table: {
    // minWidth: 1020,
  },
  tableWrapper: {
    // overflowX: 'auto',
  },
  effort100: {
    backgroundColor:'#222',
    color:'#fff'
  }
});

class Efforts extends React.Component {
  state = {
    order: 'desc',
    orderBy: 'effort',
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
    ReactGA.event({
      category: 'Settings',
      action: 'Table Sort (Effort)',
      label: orderBy + ' ' + order
    });
  };

  handleClick = (event, pace) => {

  };

  render() {
    const { classes } = this.props;
    const { order, orderBy } = this.state;
    const data = (this.props.paceUnits === 'mi') ? generateEffort(this.props.pace.spmi) : generateEffort(this.props.pace.spkm);

    rows[0]['label'] = 'Pace / ' + this.props.paceUnits;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .map(n => {
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.pace)}
                      tabIndex={-1}
                      key={n.id}
                    >
                      <TableCell>{formatPace(n.pace)}</TableCell>
                      <TableCell align='right'>{n.effort}%</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

Efforts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Efforts);
