import React  from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { Button, Grid, MenuItem, TextField, Collapse, withStyles } from '@material-ui/core';

const numberValues = ["goalHours", "goalMinutes", "goalSeconds", "goalDistance"];

const units = [
  {
    value: 'km',
    label: 'Kilometers',
  },
  {
    value: 'mi',
    label: 'Miles',
  }
];

// Race key array below determines display order in selection dropdown.
// Add a race key here to include it as an option. Define the race below.
const raceKeys = ['1k','1500m','1mi','3k','5k','10k','hm','m','50k','50mi','100k','100mi']

// Define race label, distance, and units
const races = {
  '1k': { label: '1K', distance: 1, units: 'km' },
  '1500m': { label: '1500m', distance: 1.5, units: 'km' },
  '1mi': { label: '1 Mile', distance: 1, units: 'mi' },
  '3k': { label: '3K', distance: 3, units: 'km' },
  '5k': { label: '5K', distance: 5, units: 'km' },
  '10k': { label: '10K', distance: 10, units: 'km' },
  'hm': { label: 'Half-Marathon', distance: 13.1, units: 'mi' },
  'm': { label: 'Marathon', distance: 26.2, units: 'mi' },
  '50k': { label: '50K', distance: 50, units: 'km' },
  '50mi': { label: '50 Miles', distance: 50, units: 'mi' },
  '100k': { label: '100K', distance: 100, units: 'km' },
  '100mi': { label: '100 Miles', distance: 100, units: 'mi' },
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  collapse: {
    padding: '0 16px 24px 16px',
  },
  button: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class EditGoal extends React.Component {

  handleUpdate = () => {
    this.props.toggleEditGoal();
    this.props.handleUpdatePace('goalPace');

    let race = this.props.goalRace ? races[this.props.goalRace].label : 'Custom';
    let label = this.props.goalDistance + ' ' + this.props.goalDistanceUnits + ' [' + race + '] ' + this.props.goalHours + ':' + this.props.goalMinutes + ':' + this.props.goalSeconds;
    ReactGA.event({
      category: 'Settings',
      action: 'Update Race Goal',
      label: label
    });
  };

  handleChange = name => event => {
    let value = event.target.value;
    if (numberValues.includes(name)) {
      value = Number(value);
    }
    this.props.handleUpdateField(name, value);
    if (name === 'goalDistance') {
      this.determineRace(value, this.props.goalDistanceUnits);
    }
    if (name === 'goalDistanceUnits') {
      this.determineRace(this.props.goalDistance, value);
    }
  };

  handleRaceSelection = event => {
    let value = event.target.value;
    this.props.handleUpdateField('goalRace', value);
    this.props.handleUpdateField('goalDistance', races[value].distance);
    this.props.handleUpdateField('goalDistanceUnits', races[value].units);

    ReactGA.event({
      category: 'Settings',
      action: 'Race Selection',
      label: races[value].label
    });
    // todo: set form focus to hour
  };

  determineRace = (distance, units) => {
    let raceKey = '';
    raceKeys.forEach((key) => {
      raceKey = (races[key].distance === Number(distance) && races[key].units === units) ? key : raceKey
    });
    this.props.handleUpdateField('goalRace', raceKey);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Collapse in={this.props.expanded} timeout="auto" unmountOnExit className={classes.collapse}>
          <form noValidate autoComplete="off">
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <TextField
                  id="race"
                  select
                  label="Race"
                  value={this.props.goalRace}
                  onChange={this.handleRaceSelection}
                  margin="normal"
                  fullWidth
                >
                  {raceKeys.map(key => (
                    <MenuItem key={key} value={key}>
                      {races[key].label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="distance"
                  label="Distance"
                  type="number"
                  value={this.props.goalDistance}
                  onChange={this.handleChange('goalDistance')}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="select-units"
                  select
                  label="Units"
                  value={this.props.goalDistanceUnits}
                  onChange={this.handleChange('goalDistanceUnits')}
                  margin="normal"
                  fullWidth
                >
                  {units.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="hours"
                  label="Hours"
                  type="number"
                  value={this.props.goalHours}
                  onChange={this.handleChange('goalHours')}
                  margin="normal"
                  autoFocus
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="minutes"
                  label="Minutes"
                  type="number"
                  value={this.props.goalMinutes}
                  onChange={this.handleChange('goalMinutes')}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="seconds"
                  label="Seconds"
                  type="number"
                  value={this.props.goalSeconds}
                  onChange={this.handleChange('goalSeconds')}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="outlined" fullWidth color="primary"
                        className={classes.button}
                        onClick={this.handleUpdate}
                >
                  Update Race Goal
                </Button>
              </Grid>
            </Grid>
          </form>
        </Collapse>
      </div>
    )
  }
}

EditGoal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditGoal);
