import React from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';

import { formatDuration, formatDistance } from '../utils/utils';

import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip,
} from '@material-ui/core';

// array of split intervals to display, in meters
let intervals = [100, 200, 300, 400, 600, 800, 1000,
                 1200, 1500, 1600, 2000, 3000, 5000, 10000,
                 15000, 20000, 21097.5, 25000, 30000,
                 35000, 40000, 42195]

function generateIntervals(spkm) {
  let arr = []
  for (let i = 0; i < intervals.length; i++) {
    let seconds = spkm / 1000 * intervals[i];
    arr.push({id: i, seconds: seconds, time: formatDuration(seconds), distance: intervals[i]})
  }
  return arr;
}

function desc(a, b, orderBy) {
  // handle time sorting by using seconds
  // duration value provides correct order instead of formatted string
  if (orderBy === 'time') {
    orderBy = 'seconds';
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'time', align: 'left', disablePadding: false, label: 'Time' },
  { id: 'distance', align: 'right', disablePadding: false, label: 'Distance' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  }
  render() {
    const { order, orderBy } = this.props;
    return (
      <TableHead>
        <TableRow>
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                align={row.align}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title={'Sort by ' + row.label }
                  placement={row.align === 'right' ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};

const styles = theme => ({
  root: {
    // width: '100%',
    // marginTop: theme.spacing.unit * 3,
  },
  table: {
    // minWidth: 1020,
  },
  tableWrapper: {
    // overflowX: 'auto',
  },
});

class Intervals extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'distance',
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });

    ReactGA.event({
      category: 'Settings',
      action: 'Table Sort (Intervals)',
      label: orderBy + ' ' + order
    });
  };

  handleClick = (event, id) => {
    // console.log('clicked', id);
  };

  render() {
    const { classes } = this.props;
    const { order, orderBy } = this.state;
    const data = generateIntervals(this.props.pace.spkm);

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .map(n => {
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id)}
                      tabIndex={-1}
                      key={n.id}
                    >
                      <TableCell>{n.time}</TableCell>
                      <TableCell align='right'>{formatDistance(n.distance, 'm', ' m', '0,0')}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

Intervals.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Intervals);
