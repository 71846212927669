import React from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';

import { withStyles, Table, TableBody, TableCell, TableHead,
         TableRow, TableSortLabel, Paper } from '@material-ui/core';

import { formatDuration, getDistances } from '../utils/utils';


function generateSplits(pace, distance, units) {
  // pace (obj): pace object with seconds per unit paces for mi and km
  // distance (float): distance over which to compute splits
  // units (str): 'mi' or 'km'
  let miSplits = [];
  let kmSplits = [];

  let d = getDistances(distance, units);

  if (d.mi >= 1) {
    for (let i = 1; i <= d.mi; i++) {
      miSplits.push({id: i, seconds: pace.spmi * i, time: formatDuration(pace.spmi * i), distance: i})
    }
    if (miSplits[miSplits.length - 1].distance < d.mi) { miSplits.push({id: miSplits.length + 1, seconds: pace.spmi * d.mi, time: formatDuration(pace.spmi * d.mi), distance: d.mi}) };
  }

  if (d.km >= 1) {
    for (let i = 1; i <= d.km; i++) {
      kmSplits.push({id: i, seconds: pace.spkm * i, time: formatDuration(pace.spkm * i), distance: i})
    }
    if (kmSplits[kmSplits.length - 1].distance < d.km) { kmSplits.push({id: kmSplits.length + 1, seconds: pace.spkm * d.km, time: formatDuration(pace.spkm * d.km), distance: d.km}) };
  }

  return {km: kmSplits, mi: miSplits};
}

function desc(a, b, orderBy) {
  // handle time sorting by using seconds
  // duration value provides correct order instead of formatted string
  if (orderBy === 'time') {
    orderBy = 'seconds';
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'time', align: 'left', disablePadding: false, label: 'Time' },
  { id: 'distance', align: 'right', disablePadding: false, label: 'Distance' },
];

const styles = theme => ({
  root: {
    // width: '100%',
    // marginTop: theme.spacing.unit * 3,
  },
  table: {
    // minWidth: 1020,
  },
  tableWrapper: {
    // overflowX: 'auto',
  },
});

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  }
  render() {
    const { order, orderBy } = this.props;
    return (
      <TableHead>
        <TableRow>
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                align={row.align}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <TableSortLabel

                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};

class Splits extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'distance',
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });

    ReactGA.event({
      category: 'Settings',
      action: 'Table Sort (Splits)',
      label: orderBy + ' ' + order
    });
  };

  handleClick = (event, id) => {
    // console.log('clicked', id);
  };

  render() {
    const { classes } = this.props;
    const { order, orderBy } = this.state;
    const splits = generateSplits(this.props.pace, this.props.goalDistance, this.props.goalDistanceUnits);
    const data = (this.props.paceUnits === 'mi') ? splits.mi : splits.km;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .map(n => {
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id)}
                      tabIndex={-1}
                      key={n.id}
                    >
                      <TableCell>{n.time}</TableCell>
                      <TableCell align='right'>{n.distance} {this.props.paceUnits}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }
}

Splits.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Splits);
