import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Collapse,
  Divider,
  Link,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    backgroundColor: 'transparent',
  },
  collapse: {
    padding: '24px 16px 24px 16px',
  },
  divider: {
    margin: '16px 0',
  },
});

class Info extends React.Component {

  handleClick = () => {
    this.props.toggleInfo();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Collapse in={this.props.expanded} timeout="auto" unmountOnExit className={classes.collapse}>
          <Paper className={classes.paper} elevation={0}>
            <Typography variant="h6" component="h2">
              What Pace
            </Typography>
            <Typography gutterBottom variant="subtitle2" component="p" color="textSecondary">
              <em>A tool for those in pursuit of pace - by <Link href="//ckalima.com" target="_blank" rel="noopener">ckalima</Link></em>
            </Typography>
            <Typography gutterBottom variant="body2" component="p" color="textSecondary">
              What Pace is a pace calculation tool built to assist runners training for a specific race goal. Working from a race goal distance and finishing time, the tool provides timing information for splits, intervals, and effort levels that are useful for targeted training workouts.
            </Typography>
            <Divider className={classes.divider} />
            <Typography gutterBottom variant="button" component="h3">
              Goal Race Pace
            </Typography>
            <Typography gutterBottom variant="body2" component="p" color="textSecondary">
              The goal race pace may be updated by editing the race distance and finishing time. Click the edit icon in the header to open the editing panel. Distance values can be entered in either miles or kilometers. Once updated, the tool will calculate the new goal race pace and update all timing values throughout the app.
            </Typography>
            <Typography gutterBottom variant="body2" component="p" color="textSecondary">
              The pace units may be toggled from miles to kilometers by clicking on the pace in the header.
            </Typography>
            <Divider className={classes.divider} />
            <Typography gutterBottom variant="button" component="h3">
              Splits
            </Typography>
            <Typography gutterBottom variant="body2" component="p" color="textSecondary">
              The splits panel displays the mile or kilometer splits for the provided race goal. To toggle between miles and kilometers, click on the pace in the header.
            </Typography>
            <Divider className={classes.divider} />
            <Typography gutterBottom variant="button" component="h3">
              Intervals
            </Typography>
            <Typography gutterBottom variant="body2" component="p" color="textSecondary">
              The intervals panel displays timing for various interval workout distances at the goal race pace.
            </Typography>
            <Divider className={classes.divider} />
            <Typography gutterBottom variant="button" component="h3">
              Effort
            </Typography>
            <Typography gutterBottom variant="body2" component="p" color="textSecondary">
              The effort panel displays paces as a percentage of the goal race pace.
            </Typography>
            <Typography gutterBottom variant="body2" component="p" color="textSecondary">
              Assuming the goal race pace is equivalent to a 100% effort, values are displayed for harder efforts (greater than 100%) as well as easier efforts (less than 100%). These values are useful for workouts that are tailored as percentages of race efforts.
            </Typography>
            <Divider className={classes.divider} />
            <Button size="small" color="primary" variant="text" onClick={this.handleClick}>
              Close
            </Button>
          </Paper>
        </Collapse>
      </div>
    );
  }
}

Info.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Info);

